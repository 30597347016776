<template>
  <section class="listar-corretor pr-4">
    <div class="m-1 mr-4 px-0">
      <breadcrumb title="Corretores" actualPage="Lista de Corretores" />
    </div>

    <div class="table-content">
      <vs-table
        :sst="true"
        @search="handleSearch"
        @change-page="handleChangePage"
        search
        class="table-list"
        noDataText="Nenhum corretor encontrado"
        :data="listaCorretores || []"
        :hoverFlat="true"
      >
        <template slot="thead">
          <vs-th class="text-center">CNPJ</vs-th>
          <vs-th class="text-center">Nome</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].BrokerDocument" class="text-center">
              <span class="font-semibold">{{
                data[indextr].BrokerDocument
              }}</span>
            </vs-td>

            <vs-td :data="data[indextr].BrokerName" class="text-center">
              <span class="font-semibold">{{ data[indextr].BrokerName }}</span>
            </vs-td>

            <vs-td :data="data[indextr].BrokerId" class="text-center">
              <vs-button
                color="primary"
                @click="login(data[indextr])"
                type="filled"
                >Efetuar login</vs-button
              >
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination class="mt-5" :total="numberOfPages" v-model="currentPage">
      </vs-pagination>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/axios";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Breadcrumb },
  directives: {},
  data() {
    return {
      listaCorretores: [],
      totalItems: 0,
      currentPage: 1,
      rowsPerPage: 10,
      numberOfPages: 0,
      searchValue: ""
    };
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.listarCorretores();
    }
  },
  mounted() {
    this.listarCorretores();
  },
  created() {
    this.$appInsights.trackPageView({
      name: "corretor-assessoria",
      url: window.location.href
    });
  },
  computed: {
    ...mapGetters("auth", ["advisorInfo"])
  },
  methods: {
    ...mapActions("auth", ["loginAdvisor"]),
    login(broker) {
      this.$vs.loading();
      return this.loginAdvisor({
        BrokerUniqueId: broker.BrokerUniqueId,
        senha: this.password
      })
        .then(() => {
          this.$router.push("/redirect-to-home");
        })
        .catch(errors => {
          this.$onpoint.errorModal(
            ((errors.response || {}).data || {}).error_description ||
              "Não foi possível realizar o Login."
          );
        })
        .finally(() => this.$vs.loading.close());
    },
    listarCorretores() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .post(`api/Advisor/GetSearchBrokers`, {
            SearchValue: this.searchValue,
            currentPage: this.currentPage,
            rowsPerPage: this.rowsPerPage
          })
          .then(response => {
            response = response.data.Response;
            this.listaCorretores = response.Data;
            this.totalItems = response.NumberOfRows;
            this.numberOfPages = response.NumberOfPages;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    handleSearch: _.debounce(function(searching) {
      const scope = this;

      function __search() {
        scope.searchValue = searching;
        scope.currentPage = 1;
        scope.listarCorretores();

        const pag1 = document.getElementsByClassName(
          "item-pagination vs-pagination--li"
        )[0];
        if (pag1) pag1.classList.add("is-current");
      }

      if (!searching) {
        if (this.searchValue) __search();
      } else {
        __search();
      }
    }, 750),

    handleChangePage(page) {
      this.currentPage = page;
      this.listarCorretores();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";

.table-list {
  margin-top: -32px;

  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 25px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }

    input {
      padding-left: 40px !important;
      font-size: 1.1em !important;
      width: 300px;
      border: 0 !important;
      height: 47px;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}
</style>
